.defaultSlider { position: relative; height: 600px;position: relative;float: left;width: 100%;margin-bottom: 0 !important;
  &__Slide {background-size: cover;background-repeat: no-repeat;background-position: center center; position: relative;z-index: 1;
    &:after {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 0; background: alpha($black,0.6); z-index: -1;}
  }
  &__Content { height: 600px; position: relative; float: left; width: 100%;}
  &__Box { float: left; width: 100%; position: relative;  top: 50%; transform:translateY(-50%);}
  &__Heading { text-align: center; font-size: 100px; line-height: 112px; margin: 0; padding: 0; font-weight: 300; color: $white; position: relative;}
}
.classicText {float: left;width: 100%;padding: 50px 0;text-align: left;
  h4 {font-weight: 500;color: $black;text-align: center;}
  p {font-weight: 300; color: darken($gray, 50%);}
  ul {list-style: none;
    li {font-weight: 300; color: darken($gray, 50%);}
  }
}
.techContent {background-size: 100% auto;background-repeat: no-repeat;background-position: left top ; position: relative;z-index: 1;min-height: 1500px;float: left;width: 100%;
  &__Wrap {width: 1160px;margin: 0 auto;display: block;}
  &__Name {height: 60px;line-height: 60px;color: $black;cursor: pointer;padding-left: 30px;
    img {width: 60px;float: left;line-height: 60px;padding-top: 10px;}
    h3 {font-size: 24px;text-transform: uppercase;font-weight: 400;margin: 0;float: left;line-height: 60px;margin-right: 10px;}
  }
  &__Top {float: left;width: 100%;background-size: cover;background-position: top center;background-repeat: no-repeat
  ;background-color: #a71c21;}
  &__boxWrapp {float: left;width: 100%;padding-top: 100px;}
  &__middleWrapp {float: left;width: 100%;padding-top: 150px;height: 400px;background-position: top center;background-repeat: no-repeat;background-color: #2a2a2a;}
  &__Box {float: left;width: 33.33%;height: 400px;padding-left: 5%;padding-right: 5%;text-align: center;margin-bottom: 50px;
      img {height: 60px;}
      h3 {color: $white;margin: 0;margin-top: 10px; text-transform: uppercase; font-size: 16px;text-align: left;}
      ul {padding: 0;margin: 0;}
      li {list-style: none;border-bottom: 1px solid alpha($white, 0.3); font-size: 12px;padding: 5px 0; color: $white;text-align: left;}
  }
  &__middle {float: left;width: 100%;
    &--image {width: 15%;margin-left: 30%;float: left;margin-right: 5%;
        img {max-width: 150px;}
    }
    &--content {float: left;color: $white;width: 40%;
      h3 {color: $brand; text-transform: uppercase; font-size: 16px;}
    }
  }
  &__Bottom {background: #2a2a2a;float: left;width: 100%;}
}
.videoPage {
  float: left;width: 100%;margin-top: 210px;margin-bottom: 150px;
  iframe {float: left;width: 100%;height: 550px;}
}
.demoImageMobile {display: none;}
.team {
  float: left;width: 100%;background: $white;margin-top: -60px;padding: 80px 0;position: relative;
  &__Box {float: left;width: 18%;margin: 1%;
    &--img {background-size: cover;border-radius: 100%;height: 180px;width: 180px;background-position: center center;background-repeat: no-repeat;float: left;filter: grayscale(100%);transition:300ms;position: relative;left: 50%;transform:translateX(-50%);}
    &--content {float: left;width: 100%;text-align: center;
      h4 {color: $black;font-weight: bold;font-size: 14px;margin-bottom: 0;}
      h5 {color: $gray;font-weight: lighter;font-size: 12px;margin: 0;}
    }
    &--bottom {background: #2a2a2a;float: left;width: 100%;margin-top: 20px;position: relative;z-index: 1;
      &:before {position: absolute;content: '';width: 0;height: 0;border-style: solid;border-width: 0 10px 10px 10px;border-color: transparent transparent #2a2a2a transparent;top: -10px;left: 0;right: 0;z-index: -1;margin: auto;}
      &:after {position: absolute;content: '';top: 0px;right: -10000px;left: -10000px;bottom: 0;z-index: -1;background: #ededed;}
      ul {list-style: none;padding: 20px 0;margin: 0;background: #2a2a2a;}
      li {display: block;text-transform: uppercase;color: $brand;font-size: 12px;font-weight: bold;padding: 5px 30px;border-bottom: 1px solid alpha($white, 0.1);
        &:last-of-type {
          margin-top: 20px;text-align: center;padding: 5px 25px;border-bottom: 0;
          a {color: $brand;margin-left: 0;}
        }
      }
      a {text-decoration: none;text-transform: initial;color: $gray;margin-left: 10px;font-weight: normal;}
    }
    &:hover {
      cursor: pointer;
      .team__Box--img {filter: grayscale(0%);}
    }
    .activeImage {filter: grayscale(0%);}
  }
}
.boxnum6,.boxnum11,.boxnum16,.boxnum21 {clear: both;}
@media screen and (max-width: 720px) { 
  .team {
    &__Box {width: 90%;margin-left: 5%;margin-right: 5%;}
  }
}