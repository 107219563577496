.siteHeader {position: absolute;z-index: 10000000;background-color: $gray;height: 60px; margin-top: 850px;float: left;width: 100%;}

// .js-SiteHeader {
//     &--is-Active {background:$gray;opacity: 1;visibility: visible;width: 100%;box-shadow: 0 5px 5px -5px alpha($black, 0.1);margin-top: 840px;
//     }
//     &--is-Fixed {background: $gray;position: fixed;left: 0px;width: 100%;-webkit-transition: margin 500ms cubic-bezier(0.77, 0, 0.175, 1);transition: margin 500ms cubic-bezier(0.77, 0, 0.175, 1);top: -840px;
//     }
// }
.page-template-page-homepage {
  .siteHeader {margin-top: 70vh}
}
.page-template-page-products {
  .siteHeader {display: none;}
}
.page-template-page-products-no {
  .siteHeader {display: none;}
}
.page-template-page-default {
  .siteHeader {display: none;}
}
.single-format-standard {
  .siteHeader {display: none;}
}
.page-template-page-technology {
  .siteHeader {display: none;}
}
.page-template-default {
  .siteHeader {margin-top: 600px;}
}
.page-template-page-about-new {
  .siteHeader {display: none;}
  .innerHeader {display: block !important;position: relative !important;margin-top: 0px !important;}
}
.page-id-530 {
  .labelMenu {
    width: 340px !important;
    &__Top {
      h3 {font-size: 20px !important;}
    }
  }
  .folioSlider__Text {
    display: none;
  }
}
.page-id-866 {
  .labelMenu {
    width: 340px !important;
    &__Top {
      h3 {font-size: 20px !important;}
    }
  }
  .folioSlider__Text {
    display: none;
  }
}
.page-id-1900 {
  .labelMenu {
    width: 340px !important;
    &__Top {
      h3 {font-size: 20px !important;}
    }
  }
  .folioSlider__Text {
    display: none;
  }
}
.page-id-1909 {
  .labelMenu {
    width: 340px !important;
    &__Top {
      h3 {font-size: 20px !important;}
    }
  }
}
.page-template-page-video {
  .siteHeader {display: none;}
  .logoTop {width: 100%;padding-left: 50px;background: $brand;height: 70px;}
  .mainMenu__Top {
    h3 {color: $white !important;}
    i {color: $white !important;}
  }
}