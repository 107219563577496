.mobile {
    display: none;
}
.site {overflow: hidden;}
.homeSlider {
    &__Box {

        position: absolute; width: 400px; height: 600px; margin: 0 auto; display: block;left: 0;right: 0;text-align: center;top: 50%;transform:translateY(-45%);z-index: 10;opacity: 1;transition: 600ms $ease-in-out-cubic;pointer-events: none;
        img {
            max-width: 80px;
        }

        h4 {
            font-weight: 300;text-transform: uppercase;position: relative;z-index: 1;color: $white;font-size: 18px;
            &:after {
                left: 0;right: 0;bottom: -10px; height: 1px; background: $white;position: absolute;content: ''; display: block;z-index: -1px;
            }


        }

        h3 {
            text-transform: lowercase; font-weight: 300;position: relative;z-index: 1;margin-top: 280px;color: $white;font-size: 22px;
            &:after {
                left: 0;right: 0;top: -10px; height: 1px; background: $white;position: absolute;content: ''; display: block;z-index: -1px;
            }


        }


    }
}
.homeSlider {
    width: 100%;height: 70vh;position: relative;background: $black;display: table;table-layout: fixed;
    &__Slide {
        background-size: cover; background-repeat: no-repeat; background-position: center;   background-attachment: fixed;height: 70vh;width: 50%;position: relative;z-index: 1; transition: 600ms $ease-in-out-cubic;display: table-cell;
        &:after {position: absolute;z-index: -1;background: $black;opacity: 0.6; content: '';top: 0;right: 0;bottom: 0;left: 0;transition: 600ms $ease-in-out-cubic;}
        &:hover {width: 55%;
            &:after {opacity: 0.2;}
        }
        a {text-decoration: none;width: 100%;float: left;height: 70vh;position: relative;}

    }
    &__Slide2 {
        background-size: cover; background-repeat: no-repeat;   background-attachment: fixed;   background-position: center; height: 70vh;width: 50%;position: relative;z-index: 1;transition: 600ms $ease-in-out-cubic;display: table-cell;
        &:after {position: absolute;z-index: -1;background: $black;opacity: 0.6; content: '';top: 0;right: 0;bottom: 0;left: 0;}
        &:hover {width: 55%;}
        a {text-decoration: none;width: 100%;float: left;height: 70vh;position: relative;}

    }
    &__Text {
        position: relative;height: 70vh;width: 100%;float: left;
        h2 {font-weight: 400;line-height: 1.1;text-transform: uppercase;font-size: 60px;color: $white;float: right;width: 30%;margin: 0;margin-right: 5%;position: relative;top: 50%;transform:translateY(-50%);opacity: 1;transition:500ms;text-align: right;transition: 600ms $ease-in-out-cubic;}
        p {font-weight: 300;line-height: 1.2;font-size: 16px;color: $white;float: right;width: 400px;margin: 0;margin-left: 55%;margin-right: 5%;margin-top: 20px;position: relative;top: 50%;transform:translateY(-50%);opacity: 1;transition:500ms;text-align: right;opacity: 0;transition: 600ms $ease-in-out-cubic;}
        img {max-width: 70px; float: right;position: relative;top: 52%;transform:translateY(-50%);right: -100px;opacity: 0;transition: 600ms $ease-in-out-cubic;margin-left: -100px;}

    }
    &__Text2 {
        position: relative;height: 70vh;width: 100%;float: left;
        h2 {font-weight: 400;line-height: 1.1;text-transform: uppercase;font-size: 60px;color: $white;width: 30%;margin: 0;margin-left: 5%;position: relative;top: 50%;transform:translateY(-50%);opacity: 1;transition:500ms;}

    }
}
.homeSlider__Slide:nth-of-type(2) .homeSlider__Text h2 {margin: 0;margin-left: 5%;float: left;text-align: left;margin-right: 70%;}
.homeSlider__Slide:nth-of-type(2) .homeSlider__Text p {margin: 0;margin-left: 5%;float: left;text-align: left;}
.homeSlider__Slide:nth-of-type(2) .homeSlider__Text img {margin: 0;right: auto;margin-left: 0;float: left;left: 40px;margin-right: -100px; top: 47%;}

.homeSlider__Slide:hover ~ .homeSlider__Box {opacity: 0;z-index: -30;}
.homeSlider__Slide2:hover ~ .homeSlider__Box {opacity: 0;z-index: -30;}


.homeSlider:hover h2 { opacity: 0; }
.homeSlider__Slide:hover h2 { opacity: 1;width: 400px; }
.homeSlider__Slide:hover p { opacity: 1; }
.homeSlider__Slide:hover img { opacity: 1; right: 40px;margin-left: 0;margin-right: 0;}

.homeSlider__Slide:nth-of-type(2):hover h2 { margin-left: 160px;}
.homeSlider__Slide:nth-of-type(2):hover p { margin-left: 160px;}
.homeSlider__Slide:nth-of-type(1):hover h2 { margin-right: 90px;}
.homeSlider__Slide:nth-of-type(1):hover p { margin-right: 160px;}



.news {
    float: left; width: 100%;min-height: 400px; padding: 70px;text-align: center;background-image: url("../images/home-news-sec-1920.jpg"); background-size: cover; background-position: center -10px;

    .news__More {@include moreButton($gray-light,transparent);border-size: 1px; font-weight: 300;
      &:before {background: $gray-light;}
      &:hover {color: $white;}
    }
    .floatMore {float: left;width: 100%;}
    &__Box {
        @include span-columns(4);overflow: hidden;
        &:nth-of-type(3n) {margin-right: 0;}
        a {
            float: left;width: 100%; text-align: center;transition: all .2s cubic-bezier(0.645, 0.045, 0.355, 1);display: block;text-decoration: none;transition:.5s;

        }

        &--img {
            background-size: cover; background-repeat: no-repeat; background-position: center center;height: 250px;float: left;width: 100%;
        }

        h4 {
            font-weight: 300; color: $black;float: left;width: 100%;text-align: left;padding: 0 10%;text-transform: uppercase;height: 50px;
        }

        p {
            font-weight: 300;color:  alpha($black, 0.5);float: left;width: 100%;text-align: left;padding: 0 10%;
        }


    }



}
