%loader {
  &:before { content: ""; display: block; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1000; background: $white; background-image: url(../images/bg-product.jpg); background-position: fixed; background-size: cover; background-position: center; }
  &:after { content: ""; display: block; width: 50px; height: 50px; position: fixed; top: 50%; left: 50%; margin-top: -25px; margin-left: -25px; border: 5px solid $brand; border-right: 5px solid transparent; z-index: 2000; border-radius: 50%; animation: 500ms threeFiddy linear infinite; }
  &:before, &:after { transition: 300ms; visibility: hidden; opacity: 0; }

  &.is-Visible {
    &:before, &:after { opacity: 1; visibility: visible; }
  }
}

.folioSlider {
  float: left; width: 100%;overflow: hidden;position: relative;background-size: cover;background-repeat: no-repeat;background-position: center top;background-image: url(../images/bg-product.jpg); background-position: fixed; background-size: cover;background-position: center;min-height: 100vh !important;max-height: 100vh !important;margin-bottom: 0 !important;
  &__Box {min-height: 100vh !important;height: 100vh !important;max-height: 100vh !important;
    &--inner {max-width: 1160px;width: 100%;margin: auto;display: table; position: relative;height: 80vh; top: 50%;transform: translateY(-50%);}
  }
  &__Image { width: 50%;display: table-cell; text-align: right; position: relative; left: -5%;vertical-align: middle;
    .demoImage {text-align: center;margin: 0;padding: 0;pointer-events: none;
      li {list-style: none;height: 800px;width: 100%;
        img {text-align: center;margin: auto;opacity: 0.5;transition: 100ms;}
      }
      li:first-of-type {z-index: 1;}
      &.is-Hidden { opacity: 0; visibility: hidden; }
    }
    .threesixty_images {
      opacity: 0.5;transition: 200ms $ease-in-out-cubic;
    }

    img { width: auto !important; height: 100% !important; }
    .threesixty {
      // Show 360 rotation icon
      &:after { position: absolute;content: url('../images/icon-rotate.png');left: 0;right: 0;height: 100px;background-size:auto 100%; background-repeat: no-repeat; top: 50%;z-index: 1;text-align: center;transform:translateY(-70%);opacity: 1;transition: 300ms; }
    }
    .threesixty_images {
      img {left: 50%; transform:translateX(-50%);}
    }
    &.is-MouseDown, &:hover {
      .threesixty_images { opacity: 1; }
      .threesixty {
        &:after { opacity: 0; visibility: hidden; transform:translateY(-50%); }
      }
    }
    &.is-Loading {
      .threesixty {
        &:after { opacity: 1 !important; visibility: visible !important; transform:translateY(-70%) !important; }
      }
    }
  }
&__Text {width: 100%;position: relative;display: table-cell;vertical-align: middle;left: -5%;
  h2 {font-size: 20px;font-weight: 400;text-transform: uppercase; color: $brand;}
  h4 {text-transform: uppercase; font-weight: 400;color: $gray;}
  ul {padding: 0;margin: 0;
    li {list-style: none; color: $gray; border-top: 1px solid $gray; border-bottom: 1px solid $gray;padding: 15px 0;
      &:nth-of-type(2) {border-top: 0;border-bottom: 0;}
      p {line-height: 1.2;margin: 0;}
    }
  }

}
}
.hiddenRotator {opacity: 0 !important;}
.active360 {opacity: 1 !important;}
.threesixty .spinner {z-index: 10000000 !important;margin-top: -300px !important;position: relative !important;}
// .sliderNavigation {position: absolute;top: 45%; transform: translateY(-50%);width: 50px; height: 50px; background: $brand; z-index: 10000;transition:opacity 0.1s linear;
//   &--disabled {opacity: 0;transition:opacity 0.1s linear;}
// }
.mainMenuActive {
  .center {position: relative;top: 50%;transform:translateY(-50%);}
}

@keyframes threeFiddy {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.slideNav { margin: 0; padding: 0; font-size: 14px; font-weight: 300;text-transform: uppercase;
  @extend %loader;
  li { position: absolute; top: 50%; margin-top: -10px; right: 0; opacity: 0; display: block; transition: 300ms $ease-in-out-cubic; transform: translateX(100%);z-index: 100000;
    a { color: #c60c24; line-height: 40px; display: block; cursor: pointer;background-color: #e6e6e6;z-index: 1; text-decoration: none; color: inherit; padding: 5px 60px 5px 10px; z-index: 1; position: relative;
      &:before { content: ""; display: block; transition: 300ms $ease-in-out-cubic; background: $brand url(../images/arrow_next.svg) no-repeat right center; position: absolute; z-index: -1; right: 0; top: 0; bottom: 0; width: 50px; }

      &:hover { color: $white;
        &:before { width: 100%; }
      }
    }

    &:first-child { right: auto; left: 0; transform: translateX(-100%);
      a { padding-left: 60px; padding-right: 10px;
        &:before { left: 0; background-image: url(../images/arrow_prev.svg); background-position: left center; }
      }
    }

    &.is-Active { transform: translateX(0); opacity: 1; transition: 300ms transform, 300ms opacity, 100ms background; }
  }
}
.levelDown {
  li {z-index: 100 !important;}
}