.blockMobile {
  &__Logo {display: none;}
}
.nav { width: 100%; position: relative; font-size: 14px; letter-spacing: 2.38px;float: left;text-align: left;
  ul { padding: 0; margin: 0 auto; list-style-type: none; text-align: left; display: table;height: 60px;line-height: 60px;}
  li { display: table-cell; text-align: center; vertical-align: middle; position: relative;}
  a { color: $white; text-transform: uppercase; font-size: 1em; text-decoration: none; line-height: 60px; margin: 0; display: block; position: relative;transition: 300ms $ease-in-out-quint;padding: 0 10px;

    &:before { content: ''; position: absolute; z-index: -1; width: 0; height: 0; bottom: -8px; left: 50%;  border-style: solid; border-width: 8px 8px 0 8px; border-color: $gray transparent transparent; transform: translateX(-50%);opacity: 0;transition: 300ms $ease-in-out-quint;
    }
    &:hover {color: $white;transition: 300ms $ease-in-out-quint;
      &:before { opacity: 1;transition: 300ms $ease-in-out-quint; }
    }
  }
  .current-menu-item {
    a {
      &:before {opacity: 1;transition: 300ms $ease-in-out-quint;}
    }
  }


  &--Footer { @extend .nav;width: 70%;height: 100px;line-height: 100px;
      ul {width: auto;
        li {border-radius: 0;border: 0; width: auto;height: auto;}
      }
      a { color: $gray-light;height: 100px;line-height: 100px;font-size: 12px;font-weight: 300;text-transform: initial;letter-spacing:normal;
        &:hover {color: $black;}
        &:before {display: none;}
      }
  }
}
.labelMenu {position: absolute !important;z-index: 10000;width: 200px;
  &__Top {height: 60px;line-height: 60px;color: $gray;cursor: pointer;
    img {width: 60px;float: left;line-height: 60px;padding-top: 10px;}
    h3 {font-size: 30px;text-transform: uppercase;font-weight: 400;margin: 0;float: left;line-height: 60px;margin-right: 10px;}
    i {float: left;line-height: 60px;transform:rotate(-90deg);width: 10px;transition:transform 0.1s linear;}
  }
  ul {visibility: hidden;opacity: 0;transition: visibility 0s, opacity 0.5s linear;float: right; margin: 0;padding: 0; padding-right: 20px;width: 100%;background: #f5f5f5;
    li {display: block;padding:0 0 5px 0;float: right;width: 100% !important; text-align: right;font-weight: 400;text-transform: uppercase;color: $gray;border-bottom: 1px solid #f5f5f5;}
    li.current {color: $brand;}
    a { color: inherit; text-decoration: none;
      &:hover { color: $brand; }
    }
  }
  .menuActive {visibility: visible;opacity: 1;transition: visibility 0s, opacity 0.5s linear;}
  .i-menuActive {transform:rotate(0deg);transition:transform 0.1s linear;}
}
.logoTop {position: absolute !important;z-index: 10000;width: 190px;padding-left: 50px;
  &__Top {height: 50px;line-height: 50px;color: $gray;cursor: pointer;
    img {width: 50px;float: left;line-height: 50px;padding-top: 10px;}
  }
}

.mainMenu {
  &__Top {height: 60px;line-height: 60px;color: $gray;cursor: pointer;text-align: right;position: fixed;z-index: 100000000000;float: right;right: 30px;transition: visibility 0s, opacity 0.5s linear;

    a.langLink.active {display: none;}
    a.langLink {text-transform: uppercase;text-decoration: none; color: #eaeaea;font-size: 14px;font-weight: 700;float: left;line-height: 63px;height: 60px;position: relative;right: 80px;margin-right: 10px;}
    a.videoLink {text-transform: uppercase;text-decoration: none;color: $white; font-weight: 300; font-size: 11px;margin-left: 20px;margin-right: 20px;display: inline-block;float: left;position: relative;right: 80px; background: $brand;padding: 15px 17px;height: auto;line-height: initial;margin-top: 10px;
      img {height: 15px;display: inline-block;vertical-align: middle;margin-left: 5px;margin-top:-3px;}
    }
    h3 {font-size: 18px;text-transform: uppercase;font-weight: 400;margin: 0;line-height: 60px;margin-right: 5px;display: inline-block;}
    i {line-height: 60px;width: 20px;transition:transform 0.1s linear;display: inline-block;color: $brand;font-size: 22px;}
  }
  nav {visibility: hidden;opacity: 0;float: right; margin: 0;padding: 0;position: fixed; top: 0;right: 0;bottom: 0;left: 0; background: alpha($black,0.9);z-index: -1000;text-align: center;transition:  all 0.3s linear, visibility 0s;height: 100vh;
  }
  ul {margin: 0;padding: 0;text-align: center;
    li {display: block;padding: 20px 0;font-weight: 400;text-transform: uppercase;
      a {text-decoration: none;color: $white;float: left;width: 100%;padding: 10px 0;font-size: 40px;position: relative;z-index: 1;transition:0.3s linear;
        &:after {left: 40%;right: 40%;bottom: -2%; background-color: transparent;height: 1px; position: absolute;content: '';opacity: 0;z-index: -1;transition:0.3s linear;}
        &:hover {transition:0.3s linear;
          &:after {bottom: 0;opacity: 1;transition:0.3s linear;background: $white;left: 45%;right: 45%;}
        }
      }
    }
  }
  .mainMenuActive {visibility: visible;opacity: 1;transition:  all 0.3s linear, visibility 0s;z-index: 10000000000;}
}
@media screen and (max-width: 720px) {
  .mainMenuActive {
    width: 100%;
    bottom: -1000% !important;
    position: fixed !important;padding-top: 100px !important;

  }
  .mainMenu ul li a {font-size: 20px;}
  .mainMenu__Top a.langLink {float: right;right: 170px}
  .mainMenuActive .center {
    position: relative;
    top: 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    height: 100%;
    .menu-glavni-izbornik-container {
      position: relative;top: 0%;transform:translateY(0%);float: left;
width: 100%;
    }
}
.homeSlider {height: auto;display: block;margin-top: 70px;
  &__Box {display: none;}
  &__Slide {width: 100%;height: 40vh;display: block;
    &:hover {width: 100%;}
    a {height: 40vh;}
  }
  &__Slide2 {width: 100%;height: 40vh;display: block;
    &:hover {width: 100%;}
    a {height: 40vh;}
  }
  &__Text {width: 100%;height: 40vh;display: block;text-align: center;
    h2 {font-size: 40px;text-align: center;float: none;margin: 0;width: 80%;margin-left: 10%;margin-right: 10%;text-align: center;}
    p {display: none;}
    img {display: none;}
  }
  &__Text2 {width: 100%;height: 40vh;display: block;text-align: center;
    h2 {font-size: 40px;text-align: center;float: none;margin: 0;width: 80%;margin-left: 10%;margin-right: 10%;text-align: center;}
    p {display: none;}
    img {display: none;}
  }
}
.homeSlider__Slide:nth-of-type(2) .homeSlider__Text h2 {
    margin: 0;
    margin-left:10%;
    float: left;
    text-align: center;
    margin-right: 10%;
}
.homeSlider__Slide:nth-of-type(2):hover h2 {margin-left: 10%;}
.homeSlider__Slide:hover ~ .homeSlider__Box {opacity: 1;transition:500ms;z-index: 10;}
.homeSlider__Slide2:hover ~ .homeSlider__Box {opacity: 1;transition:500ms;z-index: 10;}
.homeSlider__Slide:hover h2 {width: 80% !important;}
.news {
  &__Box {width: 100%; margin: 0;margin-bottom: 20px;
      h4 {padding: 0; height: auto;}
      p {padding: 0; height: auto;}
  }
}
.siteFooter {height: auto;
  &__Left {width: 100%;margin: 0;height: auto;text-align: center;padding: 20px 0;
    a {display: block;margin: 0;line-height: initial;margin-bottom: 20px;}
    span {display: block;margin: 0;line-height: initial;}
  }
  &__Right {width: 100%;margin: 0;height: auto;
      ul {width: 100%;text-align: center;}
  }
  .nav--Footer {display: none;}
}
.siteHeader {display: none;}
.innerSlider {height: auto;padding-top: 100px;background: $brand;
  &__Left {width: 90%;margin: 0 5%;padding: 0;display: block;height: auto;text-align: center;}
  &__Right {width: 80%;margin: 0 10%;padding: 0;display: block;height: auto;text-align: left;right: auto;}
  &__Statistics {height: auto;
    &--wrap {height: auto;
      &:before {display: none;}
    }
    &--left {width: 80%;top:auto; transform:translateY(0);margin-left: 10%;margin-right: 10%;}
    &--right {width: 100%;top:auto; transform:translateY(0);height: auto;}
    .circliful {display: block;clear:both;float: none;margin: auto;margin-bottom: 60px;}
  }
}

.techContent {
  &__Wrap {width: 100%;}
  &__Name {
    h3 {display: none;}
  }
  &__Top {float: left;width: 100%;background-size: cover;background-position: top center;background-repeat: no-repeat
  ;background-color: #a71c21;}
  &__boxWrapp {float: left;width: 100%;padding-top: 100px;}
  &__middleWrapp {float: left;width: 100%;padding-top: 150px;height: auto;background-position: top center;background-repeat: no-repeat;background-color: #2a2a2a;}
  &__Box {width: 80%;padding: 0;margin-left: 10%;margin-right: 10%;height: auto;}
  &__middle {float: left;width: 100%;
    &--image {width: 100%;margin: 0;text-align: center;
        img {max-width: 80px;}
    }
    &--content {float: left;color: $white;width: 80%;margin-left: 10%;margin-right: 10%;
      h3 {color: $brand; text-transform: uppercase; font-size: 16px;}
    }
  }
  &__Bottom {background: #2a2a2a;float: left;width: 100%;}
}

.slideNav {
  li {
    a {white-space: nowrap;text-indent: -9999px;background: transparent;}
  }
}
.page-template-page-products {overflow: hidden;}
.page-template-page-products .siteFooter {margin-top: 50px !important;}
.folioSlider {
    float: left; width: 100%;max-height: initial !important;margin-bottom: 0 !important;height: auto;min-height: auto !important;
    &__Box {min-height: auto !important;height: 100% !important;max-height: initial !important;
      &--inner {max-width: initial;width: 100%;height: auto;margin: auto;top: 0;transform:translateY(0);}
    }
    &__Image {width: 90%;display: block; left: auto; margin-left: 5%;margin-right: 5%;margin-top: 100px;margin-bottom: 50px;float: left;

      .demoImageMobile {text-align: center;margin: 0;padding: 0;transition: 100ms;text-align: center;display: block;
        height: auto;width: 100%;float: left;
        li {list-style: none;float: left;width: 100%;height: auto;
          img {text-align: center;margin: auto;opacity: 1;transition: 100ms;float: none;}
        }
        li:first-of-type {z-index: 1;
            &:after { display: none;}
         }
      }
      .threesixty {display: none;}
    }
    &__Text {width: 80%;display: block;left: auto;margin-left: 10%;margin-right: 10%;}
}
.labelMenu {
  ul {margin-top: 70px;}
}
.labelMenu__Top {position: fixed;margin-top: 5px;
  h3 {font-size: 12px;}
  // i {display: none;}
}
.labelMenu .menuActive {position: fixed;}
.blockMobile {background: $black;position: fixed;width: 100%;height: 70px;right: 0;padding-right: 30px;z-index: 10000;
  &__Logo {float: left;width: 60px;display: block;
    img {max-height: 50px;vertical-align: middle;}
  }
}
.mainMenu__Top a.videoLink {margin-top: 10px;float: right;position: relative;z-index: 100;right: 0px;margin-right: -20px;
  span {display: none;}
  img {margin-left: 0;}
}
.mainMenu__Top.activatedMenu {background: transparent;z-index: 10000000000000;}
.page-template-page-video .logoTop {display: none;}
.page-template-page-video .mainMenu__Top h3 {color: #777 !important;}
.page-template-page-video .mainMenu__Top i {color: $brand !important;}
.videoPage {margin: auto;}
.defaultSlider__Heading {line-height: 50px;font-size: 40px; }
.classicText {padding: 50px 5%;}
.logoTop {display: none;}
.page-template-page-products .blockMobile__Logo {display: none;}
.mainMenu__Top h3 {display: none;}
.page-id-155 {
  .mainMenu__Top a.videoLink {margin-left: 10px;margin-right: 10px;padding: 15px 10px;}
  .mainMenu__Top a.langLink {right: 140px;}
}
.labelMenu {
    width: 0% !important;
}
.page-id-530 {
  .labelMenu {width: 0% !important;}
  .mainMenu__Top a.videoLink {padding: 5px 7px;margin-left: 5px;margin-right: 5px;margin-top: 17px;}
  .labelMenu__Top h3 {
      font-size: 12px !important;width: 30%;line-height: 1;padding-top: 17px;
  }
  .mainMenu__Top a.langLink {right: 130px;}
  .mainMenu__Top {padding-right: 10px;padding-left:10px;right: 0;}
}
.folioSlider__Image img {height: auto !important;}
}
@media screen and (max-width: 960px) {
  .contactPage__Right {position: relative;width: 100%;background: #444;height: 400px;
    &:before {display: none;}
    
  }
  .contactPage__Inner {float: left;width: 80%;padding-left: 10%;padding-right: 10%;}
  .map {
  float: left;width: 100%; height: 300px;transform:translateX(0%);
}
}