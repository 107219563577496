.siteFooter { background: $white; position: relative; width: 100%; overflow: hidden; height: 100px;
  &__Left {float: left;width: 50%;height: 100px;line-height: 100px;padding-left: 2%;
    img {max-width: 140px;vertical-align: middle;display: inline-block;}
    span {display: inline-block; margin-left: 3%;font-weight: 300;color: $gray-light; font-size: 12px;} 
  }
   &__Right {
    float: left;width: 50%;height: 100px; line-height: 100px; text-align: right;padding-right: 2%;
    ul {padding: 0;margin: 0;width: 30%;float: right;
      li {
        list-style-type: none; text-align: left;display: inline-block;color: $black;height: 40px;width: 40px;border: 1px solid $black; border-radius: 100%; text-align: center; line-height: 40px;
        i {font-size: 20px;}
      }
    }
   }
}

.newsletter { text-align: center; margin-top: 40px; font-size: 0;
  &__Input { padding: 10px 20px; margin: 0; border: 2px solid white; text-align: left; width: 100%; max-width: 350px; font-size: $font-size; transition: 300ms; height: 50px;
  }
  &__Submit { @include moreButton($black); padding: 0 60px; font-size: 14px; transition: 300ms;height: 50px;
    &:hover { background: $black; color: $white; }
  }
}

.page-template-page-products {
  .siteFooter {margin-top: -100px !important;}
}
.goldenImg {max-width: 80px !important;margin-right: 10px;}