@charset "UTF-8";
@import url(../../assets/bower_components/normalize.css/normalize.css);
/* Slider */
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&subset=latin-ext';
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  /*
    img {
        display: block;
    }
    */
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px\9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

body {
  font: 16px/1.5 "Open Sans", sans-serif; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif; }

.threesixty {
  position: relative;
  overflow: hidden;
  margin: 0 auto; }

.threesixty .threesixty_images {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0; }

.threesixty .threesixty_images img {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto; }

.threesixty .threesixty_images img.previous-image {
  visibility: hidden;
  /*
           Do not put here any other styles.
           It causes massive recalc / repaints and image flickering,
           especially on Firefox.
        */ }

.threesixty .threesixty_images img.current-image {
  visibility: visible;
  /*
           Do not put here any other styles.
           It causes massive recalc / repaints and image flickering,
           especially on Firefox.
        */ }

.threesixty .spinner {
  width: 60px;
  display: block;
  margin: 0 auto;
  height: 30px;
  background: #333;
  background: rgba(0, 0, 0, 0.7);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.threesixty .spinner span {
  font-family: Arial, "MS Trebuchet", sans-serif;
  font-size: 12px;
  font-weight: bolder;
  color: #FFF;
  text-align: center;
  line-height: 30px;
  display: block; }

.threesixty .nav_bar {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 11; }

.threesixty .nav_bar a {
  display: block;
  width: 32px;
  height: 32px;
  float: left;
  background: url(/assets/sprites.png) no-repeat;
  text-indent: -99999px; }

.threesixty .nav_bar a.nav_bar_play {
  background-position: 0 0 !important; }

.threesixty .nav_bar a.nav_bar_previous {
  background-position: 0 -73px !important; }

.threesixty .nav_bar a.nav_bar_stop {
  background-position: 0 -37px !important; }

.threesixty .nav_bar a.nav_bar_next {
  background-position: 0 -104px !important; }

/* html */
.threesixty:-webkit-full-screen {
  background: #ffffff;
  width: 100%;
  height: 100%;
  margin-top: 0;
  padding-top: 200px; }

.threesixty:-moz-full-screen {
  background: #ffffff;
  width: 100%;
  height: 100%;
  margin-top: 0;
  padding-top: 200px; }

#__bs_notify__ {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -125px !important;
  width: 250px;
  height: 50px;
  border-radius: 0 !important;
  opacity: 0.5; }

.center {
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto; }
  .center::after {
    clear: both;
    content: "";
    display: table; }

.clearfix::after {
  clear: both;
  content: "";
  display: table; }

::selection {
  background: #cccccc;
  text-shadow: none; }

.t-Title, .t-Title--with-MarginBottom {
  text-transform: uppercase;
  font-size: 2.25em;
  line-height: 44px;
  letter-spacing: 3.5px;
  margin-top: 100px;
  font-weight: 300;
  margin-bottom: 0;
  text-align: center; }
  .t-Title--with-MarginBottom {
    margin-bottom: 70px; }

.t-input-Select {
  background: #777;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  transition: 200ms;
  backface-visibility: hidden;
  margin-right: 10px; }
  .t-input-Select option {
    background: transparent; }
  .t-input-Select:hover {
    background: #6a6a6a; }
    .t-input-Select:hover:after {
      background-color: #6a6a6a; }
  .t-input-Select:active {
    transform: scale(0.97);
    transition: 60ms; }
  .t-input-Select:after {
    display: block;
    content: "";
    position: absolute;
    width: 40px;
    height: 100%;
    line-height: 43px;
    font-size: 0.75em;
    color: #999999;
    top: 0;
    right: 0;
    text-align: center;
    background: no-repeat center center;
    background-color: #777;
    pointer-events: none;
    transition: 200ms; }
  .t-input-Select select {
    border: 0;
    -webkit-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    padding: 9px 10px 8px;
    outline: none;
    cursor: pointer;
    color: #4d4d4d;
    display: block;
    background: #FFF;
    background: transparent;
    padding-right: 40px;
    display: block; }
  .t-input-Select select[disabled] {
    color: rgba(0, 0, 0, 0.3); }

.t-MoreButton {
  border: 1px #FFF solid;
  display: inline-block;
  padding: 0 30px;
  line-height: 46px;
  text-decoration: none;
  letter-spacing: 2.5px;
  font-size: 14px;
  text-transform: uppercase;
  color: #FFF;
  position: relative;
  overflow: hidden;
  z-index: 1;
  vertical-align: bottom;
  background: transparent;
  transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
  .t-MoreButton:hover {
    color: #000; }
    .t-MoreButton:hover:before {
      opacity: 1;
      right: -40px;
      left: -40px; }
  .t-MoreButton:before {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    top: -10px;
    bottom: -10px;
    transform: skew(40deg);
    background: #FFF;
    left: 100%;
    right: 100%;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }

img {
  max-width: 100%;
  height: auto; }

a {
  cursor: pointer; }

img.alignright {
  float: right;
  margin: 0 0 1em 1em; }

img.alignleft {
  float: left;
  margin: 0 1em 1em 0; }

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.alignright {
  float: right; }

.alignleft {
  float: left; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.navigation li.active a {
  color: #f2c371; }

.mobile {
  display: none; }

.site {
  overflow: hidden; }

.homeSlider__Box {
  position: absolute;
  width: 400px;
  height: 600px;
  margin: 0 auto;
  display: block;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-45%);
  z-index: 10;
  opacity: 1;
  transition: 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none; }
  .homeSlider__Box img {
    max-width: 80px; }
  .homeSlider__Box h4 {
    font-weight: 300;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    color: #FFF;
    font-size: 18px; }
    .homeSlider__Box h4:after {
      left: 0;
      right: 0;
      bottom: -10px;
      height: 1px;
      background: #FFF;
      position: absolute;
      content: '';
      display: block;
      z-index: -1px; }
  .homeSlider__Box h3 {
    text-transform: lowercase;
    font-weight: 300;
    position: relative;
    z-index: 1;
    margin-top: 280px;
    color: #FFF;
    font-size: 22px; }
    .homeSlider__Box h3:after {
      left: 0;
      right: 0;
      top: -10px;
      height: 1px;
      background: #FFF;
      position: absolute;
      content: '';
      display: block;
      z-index: -1px; }

.homeSlider {
  width: 100%;
  height: 70vh;
  position: relative;
  background: #000;
  display: table;
  table-layout: fixed; }
  .homeSlider__Slide {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    height: 70vh;
    width: 50%;
    position: relative;
    z-index: 1;
    transition: 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
    display: table-cell; }
    .homeSlider__Slide:after {
      position: absolute;
      z-index: -1;
      background: #000;
      opacity: 0.6;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: 600ms cubic-bezier(0.645, 0.045, 0.355, 1); }
    .homeSlider__Slide:hover {
      width: 55%; }
      .homeSlider__Slide:hover:after {
        opacity: 0.2; }
    .homeSlider__Slide a {
      text-decoration: none;
      width: 100%;
      float: left;
      height: 70vh;
      position: relative; }
  .homeSlider__Slide2 {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: 70vh;
    width: 50%;
    position: relative;
    z-index: 1;
    transition: 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
    display: table-cell; }
    .homeSlider__Slide2:after {
      position: absolute;
      z-index: -1;
      background: #000;
      opacity: 0.6;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .homeSlider__Slide2:hover {
      width: 55%; }
    .homeSlider__Slide2 a {
      text-decoration: none;
      width: 100%;
      float: left;
      height: 70vh;
      position: relative; }
  .homeSlider__Text {
    position: relative;
    height: 70vh;
    width: 100%;
    float: left; }
    .homeSlider__Text h2 {
      font-weight: 400;
      line-height: 1.1;
      text-transform: uppercase;
      font-size: 60px;
      color: #FFF;
      float: right;
      width: 30%;
      margin: 0;
      margin-right: 5%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1;
      transition: 500ms;
      text-align: right;
      transition: 600ms cubic-bezier(0.645, 0.045, 0.355, 1); }
    .homeSlider__Text p {
      font-weight: 300;
      line-height: 1.2;
      font-size: 16px;
      color: #FFF;
      float: right;
      width: 400px;
      margin: 0;
      margin-left: 55%;
      margin-right: 5%;
      margin-top: 20px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1;
      transition: 500ms;
      text-align: right;
      opacity: 0;
      transition: 600ms cubic-bezier(0.645, 0.045, 0.355, 1); }
    .homeSlider__Text img {
      max-width: 70px;
      float: right;
      position: relative;
      top: 52%;
      transform: translateY(-50%);
      right: -100px;
      opacity: 0;
      transition: 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
      margin-left: -100px; }
  .homeSlider__Text2 {
    position: relative;
    height: 70vh;
    width: 100%;
    float: left; }
    .homeSlider__Text2 h2 {
      font-weight: 400;
      line-height: 1.1;
      text-transform: uppercase;
      font-size: 60px;
      color: #FFF;
      width: 30%;
      margin: 0;
      margin-left: 5%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1;
      transition: 500ms; }

.homeSlider__Slide:nth-of-type(2) .homeSlider__Text h2 {
  margin: 0;
  margin-left: 5%;
  float: left;
  text-align: left;
  margin-right: 70%; }

.homeSlider__Slide:nth-of-type(2) .homeSlider__Text p {
  margin: 0;
  margin-left: 5%;
  float: left;
  text-align: left; }

.homeSlider__Slide:nth-of-type(2) .homeSlider__Text img {
  margin: 0;
  right: auto;
  margin-left: 0;
  float: left;
  left: 40px;
  margin-right: -100px;
  top: 47%; }

.homeSlider__Slide:hover ~ .homeSlider__Box {
  opacity: 0;
  z-index: -30; }

.homeSlider__Slide2:hover ~ .homeSlider__Box {
  opacity: 0;
  z-index: -30; }

.homeSlider:hover h2 {
  opacity: 0; }

.homeSlider__Slide:hover h2 {
  opacity: 1;
  width: 400px; }

.homeSlider__Slide:hover p {
  opacity: 1; }

.homeSlider__Slide:hover img {
  opacity: 1;
  right: 40px;
  margin-left: 0;
  margin-right: 0; }

.homeSlider__Slide:nth-of-type(2):hover h2 {
  margin-left: 160px; }

.homeSlider__Slide:nth-of-type(2):hover p {
  margin-left: 160px; }

.homeSlider__Slide:nth-of-type(1):hover h2 {
  margin-right: 90px; }

.homeSlider__Slide:nth-of-type(1):hover p {
  margin-right: 160px; }

.news {
  float: left;
  width: 100%;
  min-height: 400px;
  padding: 70px;
  text-align: center;
  background-image: url("../images/home-news-sec-1920.jpg");
  background-size: cover;
  background-position: center -10px; }
  .news .news__More {
    border: 1px #999 solid;
    display: inline-block;
    padding: 0 30px;
    line-height: 46px;
    text-decoration: none;
    letter-spacing: 2.5px;
    font-size: 14px;
    text-transform: uppercase;
    color: #999;
    position: relative;
    overflow: hidden;
    z-index: 1;
    vertical-align: bottom;
    background: transparent;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
    border-size: 1px;
    font-weight: 300; }
    .news .news__More:hover {
      color: #000; }
      .news .news__More:hover:before {
        opacity: 1;
        right: -40px;
        left: -40px; }
    .news .news__More:before {
      content: '';
      display: block;
      z-index: -1;
      position: absolute;
      top: -10px;
      bottom: -10px;
      transform: skew(40deg);
      background: #FFF;
      left: 100%;
      right: 100%;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
    .news .news__More:before {
      background: #999; }
    .news .news__More:hover {
      color: #FFF; }
  .news .floatMore {
    float: left;
    width: 100%; }
  .news__Box {
    float: left;
    display: block;
    margin-right: 3.44828%;
    width: 31.03448%;
    overflow: hidden; }
    .news__Box:last-child {
      margin-right: 0; }
    .news__Box:nth-of-type(3n) {
      margin-right: 0; }
    .news__Box a {
      float: left;
      width: 100%;
      text-align: center;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      display: block;
      text-decoration: none;
      transition: .5s; }
    .news__Box--img {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 250px;
      float: left;
      width: 100%; }
    .news__Box h4 {
      font-weight: 300;
      color: #000;
      float: left;
      width: 100%;
      text-align: left;
      padding: 0 10%;
      text-transform: uppercase;
      height: 50px; }
    .news__Box p {
      font-weight: 300;
      color: rgba(0, 0, 0, 0.5);
      float: left;
      width: 100%;
      text-align: left;
      padding: 0 10%; }

.defaultSlider {
  position: relative;
  height: 600px;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 0 !important; }
  .defaultSlider__Slide {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    z-index: 1; }
    .defaultSlider__Slide:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: -1; }
  .defaultSlider__Content {
    height: 600px;
    position: relative;
    float: left;
    width: 100%; }
  .defaultSlider__Box {
    float: left;
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .defaultSlider__Heading {
    text-align: center;
    font-size: 100px;
    line-height: 112px;
    margin: 0;
    padding: 0;
    font-weight: 300;
    color: #FFF;
    position: relative; }

.classicText {
  float: left;
  width: 100%;
  padding: 50px 0;
  text-align: left; }
  .classicText h4 {
    font-weight: 500;
    color: #000;
    text-align: center; }
  .classicText p {
    font-weight: 300;
    color: black; }
  .classicText ul {
    list-style: none; }
    .classicText ul li {
      font-weight: 300;
      color: black; }

.techContent {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: left top;
  position: relative;
  z-index: 1;
  min-height: 1500px;
  float: left;
  width: 100%; }
  .techContent__Wrap {
    width: 1160px;
    margin: 0 auto;
    display: block; }
  .techContent__Name {
    height: 60px;
    line-height: 60px;
    color: #000;
    cursor: pointer;
    padding-left: 30px; }
    .techContent__Name img {
      width: 60px;
      float: left;
      line-height: 60px;
      padding-top: 10px; }
    .techContent__Name h3 {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 400;
      margin: 0;
      float: left;
      line-height: 60px;
      margin-right: 10px; }
  .techContent__Top {
    float: left;
    width: 100%;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #a71c21; }
  .techContent__boxWrapp {
    float: left;
    width: 100%;
    padding-top: 100px; }
  .techContent__middleWrapp {
    float: left;
    width: 100%;
    padding-top: 150px;
    height: 400px;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #2a2a2a; }
  .techContent__Box {
    float: left;
    width: 33.33%;
    height: 400px;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
    margin-bottom: 50px; }
    .techContent__Box img {
      height: 60px; }
    .techContent__Box h3 {
      color: #FFF;
      margin: 0;
      margin-top: 10px;
      text-transform: uppercase;
      font-size: 16px;
      text-align: left; }
    .techContent__Box ul {
      padding: 0;
      margin: 0; }
    .techContent__Box li {
      list-style: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      font-size: 12px;
      padding: 5px 0;
      color: #FFF;
      text-align: left; }
  .techContent__middle {
    float: left;
    width: 100%; }
    .techContent__middle--image {
      width: 15%;
      margin-left: 30%;
      float: left;
      margin-right: 5%; }
      .techContent__middle--image img {
        max-width: 150px; }
    .techContent__middle--content {
      float: left;
      color: #FFF;
      width: 40%; }
      .techContent__middle--content h3 {
        color: #c20e1a;
        text-transform: uppercase;
        font-size: 16px; }
  .techContent__Bottom {
    background: #2a2a2a;
    float: left;
    width: 100%; }

.videoPage {
  float: left;
  width: 100%;
  margin-top: 210px;
  margin-bottom: 150px; }
  .videoPage iframe {
    float: left;
    width: 100%;
    height: 550px; }

.demoImageMobile {
  display: none; }

.team {
  float: left;
  width: 100%;
  background: #FFF;
  margin-top: -60px;
  padding: 80px 0;
  position: relative; }
  .team__Box {
    float: left;
    width: 18%;
    margin: 1%; }
    .team__Box--img {
      background-size: cover;
      border-radius: 100%;
      height: 180px;
      width: 180px;
      background-position: center center;
      background-repeat: no-repeat;
      float: left;
      filter: grayscale(100%);
      transition: 300ms;
      position: relative;
      left: 50%;
      transform: translateX(-50%); }
    .team__Box--content {
      float: left;
      width: 100%;
      text-align: center; }
      .team__Box--content h4 {
        color: #000;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 0; }
      .team__Box--content h5 {
        color: #777;
        font-weight: lighter;
        font-size: 12px;
        margin: 0; }
    .team__Box--bottom {
      background: #2a2a2a;
      float: left;
      width: 100%;
      margin-top: 20px;
      position: relative;
      z-index: 1; }
      .team__Box--bottom:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #2a2a2a transparent;
        top: -10px;
        left: 0;
        right: 0;
        z-index: -1;
        margin: auto; }
      .team__Box--bottom:after {
        position: absolute;
        content: '';
        top: 0px;
        right: -10000px;
        left: -10000px;
        bottom: 0;
        z-index: -1;
        background: #ededed; }
      .team__Box--bottom ul {
        list-style: none;
        padding: 20px 0;
        margin: 0;
        background: #2a2a2a; }
      .team__Box--bottom li {
        display: block;
        text-transform: uppercase;
        color: #c20e1a;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
        .team__Box--bottom li:last-of-type {
          margin-top: 20px;
          text-align: center;
          padding: 5px 25px;
          border-bottom: 0; }
          .team__Box--bottom li:last-of-type a {
            color: #c20e1a;
            margin-left: 0; }
      .team__Box--bottom a {
        text-decoration: none;
        text-transform: initial;
        color: #777;
        margin-left: 10px;
        font-weight: normal; }
    .team__Box:hover {
      cursor: pointer; }
      .team__Box:hover .team__Box--img {
        filter: grayscale(0%); }
    .team__Box .activeImage {
      filter: grayscale(0%); }

.boxnum6, .boxnum11, .boxnum16, .boxnum21 {
  clear: both; }

@media screen and (max-width: 720px) {
  .team__Box {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%; } }

.innerSlider {
  float: left;
  width: 100%;
  overflow: hidden;
  height: 900px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-image: url(../images/bg-onama-top.png); }
  .innerSlider__Left {
    width: 30%;
    padding-left: 15%;
    text-align: right;
    padding-right: 7%;
    position: relative;
    display: table-cell;
    vertical-align: bottom;
    height: 550px; }
    .innerSlider__Left img {
      max-width: 100px; }
    .innerSlider__Left h3 {
      color: #FFF;
      font-size: 40px;
      text-transform: uppercase;
      line-height: 1.2;
      margin-bottom: 0; }
  .innerSlider__Right {
    width: 40%;
    padding-right: 10%;
    padding-left: 2%;
    position: relative;
    display: table-cell;
    vertical-align: bottom;
    height: 550px;
    right: 5%; }
    .innerSlider__Right p {
      text-align: justify;
      color: #FFF;
      margin: 0;
      line-height: 1.2;
      font-weight: 300; }
    .innerSlider__Right h4 {
      color: #FFF;
      margin: 0;
      margin-top: 10px;
      text-transform: uppercase; }
  .innerSlider__Statistics {
    background: rgba(0, 0, 0, 0.7);
    float: left;
    width: 100%;
    height: 250px;
    margin-top: 50px; }
    .innerSlider__Statistics .circliful {
      float: right;
      font-weight: 300; }
    .innerSlider__Statistics .circle-text {
      color: #FFF;
      font-weight: 300; }
      .innerSlider__Statistics .circle-text i {
        display: none; }
    .innerSlider__Statistics .filled .circle-text {
      color: #000; }
    .innerSlider__Statistics .circle-info {
      top: 150px;
      color: #FFF;
      line-height: 1.2 !important; }
    .innerSlider__Statistics--wrap {
      height: 250px;
      float: left;
      width: 100%;
      position: relative;
      z-index: 1; }
      .innerSlider__Statistics--wrap:before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 0;
        height: 0;
        top: 0px;
        left: 10%;
        border-style: solid;
        border-width: 20px 20px 0 20px;
        border-color: #cf212e transparent transparent;
        transform: translateX(-50%);
        opacity: 1;
        transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
    .innerSlider__Statistics--left {
      float: left;
      width: 30%;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: #FFF;
      font-weight: 300; }
    .innerSlider__Statistics--right {
      float: left;
      width: 70%;
      height: 200px;
      padding-top: 25px; }

.page-template-page-homepage .menu li:first-of-type a:before {
  opacity: 1;
  transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }

.page-template-page-about .menu li:first-of-type a:before {
  opacity: 1;
  transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }

.contactPage {
  height: 700px;
  float: left;
  width: 100%;
  position: relative; }
  .contactPage__Right {
    float: right;
    height: 700px;
    width: 1000px;
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    z-index: 1; }
    .contactPage__Right:before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-top: 700px solid #444;
      border-left: 250px solid transparent;
      content: '';
      z-index: -1; }
  .contactPage__Inner {
    width: 60%;
    float: right;
    transform: translateY(-50%);
    top: 50%;
    position: relative; }
    .contactPage__Inner img {
      max-width: 90px; }
    .contactPage__Inner ul {
      padding: 0;
      margin: 0;
      margin-top: 20px; }
      .contactPage__Inner ul li {
        list-style: none;
        color: #FFF;
        text-transform: uppercase; }
        .contactPage__Inner ul li a {
          text-decoration: none;
          color: #FFF;
          margin-left: 5px;
          margin-right: 5px; }
        .contactPage__Inner ul li a.mail {
          color: #c20e1a;
          text-transform: lowercase; }
    .contactPage__Inner ul.social {
      padding: 0;
      margin: 0;
      margin-top: 40px; }
      .contactPage__Inner ul.social li {
        list-style-type: none;
        text-align: left;
        float: left;
        margin-right: 5px;
        color: #FFF;
        height: 30px;
        width: 30px;
        border: 1px solid #FFF;
        border-radius: 100%;
        text-align: center;
        line-height: 26px; }
        .contactPage__Inner ul.social li i {
          font-size: 16px; }

.map {
  float: left;
  width: 120%;
  height: 700px;
  transform: translateX(-30%); }

.page-template-page-contact .innerSlider {
  height: 450px; }

.page-template-page-contact .siteHeader {
  margin-top: 400px; }

.page-template-page-contact .innerSlider__Statistics {
  display: none; }

.page-template-page-contact .innerSlider__Left {
  float: left;
  width: 100%;
  height: 400px;
  padding: 0;
  text-align: center;
  line-height: 400px; }
  .page-template-page-contact .innerSlider__Left img {
    vertical-align: middle;
    max-height: 100px;
    max-width: none; }

.slideNav:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: #FFF;
  background-image: url(../images/bg-product.jpg);
  background-position: fixed;
  background-size: cover;
  background-position: center; }

.slideNav:after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  border: 5px solid #c20e1a;
  border-right: 5px solid transparent;
  z-index: 2000;
  border-radius: 50%;
  animation: 500ms threeFiddy linear infinite; }

.slideNav:before, .slideNav:after {
  transition: 300ms;
  visibility: hidden;
  opacity: 0; }

.is-Visible.slideNav:before, .is-Visible.slideNav:after {
  opacity: 1;
  visibility: visible; }

.folioSlider {
  float: left;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-image: url(../images/bg-product.jpg);
  background-position: fixed;
  background-size: cover;
  background-position: center;
  min-height: 100vh !important;
  max-height: 100vh !important;
  margin-bottom: 0 !important; }
  .folioSlider__Box {
    min-height: 100vh !important;
    height: 100vh !important;
    max-height: 100vh !important; }
    .folioSlider__Box--inner {
      max-width: 1160px;
      width: 100%;
      margin: auto;
      display: table;
      position: relative;
      height: 80vh;
      top: 50%;
      transform: translateY(-50%); }
  .folioSlider__Image {
    width: 50%;
    display: table-cell;
    text-align: right;
    position: relative;
    left: -5%;
    vertical-align: middle; }
    .folioSlider__Image .demoImage {
      text-align: center;
      margin: 0;
      padding: 0;
      pointer-events: none; }
      .folioSlider__Image .demoImage li {
        list-style: none;
        height: 800px;
        width: 100%; }
        .folioSlider__Image .demoImage li img {
          text-align: center;
          margin: auto;
          opacity: 0.5;
          transition: 100ms; }
      .folioSlider__Image .demoImage li:first-of-type {
        z-index: 1; }
      .folioSlider__Image .demoImage.is-Hidden {
        opacity: 0;
        visibility: hidden; }
    .folioSlider__Image .threesixty_images {
      opacity: 0.5;
      transition: 200ms cubic-bezier(0.645, 0.045, 0.355, 1); }
    .folioSlider__Image img {
      width: auto !important;
      height: 100% !important; }
    .folioSlider__Image .threesixty:after {
      position: absolute;
      content: url("../images/icon-rotate.png");
      left: 0;
      right: 0;
      height: 100px;
      background-size: auto 100%;
      background-repeat: no-repeat;
      top: 50%;
      z-index: 1;
      text-align: center;
      transform: translateY(-70%);
      opacity: 1;
      transition: 300ms; }
    .folioSlider__Image .threesixty_images img {
      left: 50%;
      transform: translateX(-50%); }
    .folioSlider__Image.is-MouseDown .threesixty_images, .folioSlider__Image:hover .threesixty_images {
      opacity: 1; }
    .folioSlider__Image.is-MouseDown .threesixty:after, .folioSlider__Image:hover .threesixty:after {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-50%); }
    .folioSlider__Image.is-Loading .threesixty:after {
      opacity: 1 !important;
      visibility: visible !important;
      transform: translateY(-70%) !important; }
  .folioSlider__Text {
    width: 100%;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    left: -5%; }
    .folioSlider__Text h2 {
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      color: #c20e1a; }
    .folioSlider__Text h4 {
      text-transform: uppercase;
      font-weight: 400;
      color: #777; }
    .folioSlider__Text ul {
      padding: 0;
      margin: 0; }
      .folioSlider__Text ul li {
        list-style: none;
        color: #777;
        border-top: 1px solid #777;
        border-bottom: 1px solid #777;
        padding: 15px 0; }
        .folioSlider__Text ul li:nth-of-type(2) {
          border-top: 0;
          border-bottom: 0; }
        .folioSlider__Text ul li p {
          line-height: 1.2;
          margin: 0; }

.hiddenRotator {
  opacity: 0 !important; }

.active360 {
  opacity: 1 !important; }

.threesixty .spinner {
  z-index: 10000000 !important;
  margin-top: -300px !important;
  position: relative !important; }

.mainMenuActive .center {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

@keyframes threeFiddy {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.slideNav {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase; }
  .slideNav li {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 0;
    opacity: 0;
    display: block;
    transition: 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: translateX(100%);
    z-index: 100000; }
    .slideNav li a {
      color: #c60c24;
      line-height: 40px;
      display: block;
      cursor: pointer;
      background-color: #e6e6e6;
      z-index: 1;
      text-decoration: none;
      color: inherit;
      padding: 5px 60px 5px 10px;
      z-index: 1;
      position: relative; }
      .slideNav li a:before {
        content: "";
        display: block;
        transition: 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
        background: #c20e1a url(../images/arrow_next.svg) no-repeat right center;
        position: absolute;
        z-index: -1;
        right: 0;
        top: 0;
        bottom: 0;
        width: 50px; }
      .slideNav li a:hover {
        color: #FFF; }
        .slideNav li a:hover:before {
          width: 100%; }
    .slideNav li:first-child {
      right: auto;
      left: 0;
      transform: translateX(-100%); }
      .slideNav li:first-child a {
        padding-left: 60px;
        padding-right: 10px; }
        .slideNav li:first-child a:before {
          left: 0;
          background-image: url(../images/arrow_prev.svg);
          background-position: left center; }
    .slideNav li.is-Active {
      transform: translateX(0);
      opacity: 1;
      transition: 300ms transform, 300ms opacity, 100ms background; }

.levelDown li {
  z-index: 100 !important; }

.siteHeader {
  position: absolute;
  z-index: 10000000;
  background-color: #777;
  height: 60px;
  margin-top: 850px;
  float: left;
  width: 100%; }

.page-template-page-homepage .siteHeader {
  margin-top: 70vh; }

.page-template-page-products .siteHeader {
  display: none; }

.page-template-page-products-no .siteHeader {
  display: none; }

.page-template-page-default .siteHeader {
  display: none; }

.single-format-standard .siteHeader {
  display: none; }

.page-template-page-technology .siteHeader {
  display: none; }

.page-template-default .siteHeader {
  margin-top: 600px; }

.page-template-page-about-new .siteHeader {
  display: none; }

.page-template-page-about-new .innerHeader {
  display: block !important;
  position: relative !important;
  margin-top: 0px !important; }

.page-id-530 .labelMenu {
  width: 340px !important; }
  .page-id-530 .labelMenu__Top h3 {
    font-size: 20px !important; }

.page-id-530 .folioSlider__Text {
  display: none; }

.page-id-866 .labelMenu {
  width: 340px !important; }
  .page-id-866 .labelMenu__Top h3 {
    font-size: 20px !important; }

.page-id-866 .folioSlider__Text {
  display: none; }

.page-id-1900 .labelMenu {
  width: 340px !important; }
  .page-id-1900 .labelMenu__Top h3 {
    font-size: 20px !important; }

.page-id-1900 .folioSlider__Text {
  display: none; }

.page-id-1909 .labelMenu {
  width: 340px !important; }
  .page-id-1909 .labelMenu__Top h3 {
    font-size: 20px !important; }

.page-template-page-video .siteHeader {
  display: none; }

.page-template-page-video .logoTop {
  width: 100%;
  padding-left: 50px;
  background: #c20e1a;
  height: 70px; }

.page-template-page-video .mainMenu__Top h3 {
  color: #FFF !important; }

.page-template-page-video .mainMenu__Top i {
  color: #FFF !important; }

.siteFooter {
  background: #FFF;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100px; }
  .siteFooter__Left {
    float: left;
    width: 50%;
    height: 100px;
    line-height: 100px;
    padding-left: 2%; }
    .siteFooter__Left img {
      max-width: 140px;
      vertical-align: middle;
      display: inline-block; }
    .siteFooter__Left span {
      display: inline-block;
      margin-left: 3%;
      font-weight: 300;
      color: #999;
      font-size: 12px; }
  .siteFooter__Right {
    float: left;
    width: 50%;
    height: 100px;
    line-height: 100px;
    text-align: right;
    padding-right: 2%; }
    .siteFooter__Right ul {
      padding: 0;
      margin: 0;
      width: 30%;
      float: right; }
      .siteFooter__Right ul li {
        list-style-type: none;
        text-align: left;
        display: inline-block;
        color: #000;
        height: 40px;
        width: 40px;
        border: 1px solid #000;
        border-radius: 100%;
        text-align: center;
        line-height: 40px; }
        .siteFooter__Right ul li i {
          font-size: 20px; }

.newsletter {
  text-align: center;
  margin-top: 40px;
  font-size: 0; }
  .newsletter__Input {
    padding: 10px 20px;
    margin: 0;
    border: 2px solid white;
    text-align: left;
    width: 100%;
    max-width: 350px;
    font-size: 16px;
    transition: 300ms;
    height: 50px; }
  .newsletter__Submit {
    border: 1px #000 solid;
    display: inline-block;
    padding: 0 30px;
    line-height: 46px;
    text-decoration: none;
    letter-spacing: 2.5px;
    font-size: 14px;
    text-transform: uppercase;
    color: #000;
    position: relative;
    overflow: hidden;
    z-index: 1;
    vertical-align: bottom;
    background: transparent;
    transition: 900ms cubic-bezier(0.23, 1, 0.32, 1);
    padding: 0 60px;
    font-size: 14px;
    transition: 300ms;
    height: 50px; }
    .newsletter__Submit:hover {
      color: #000; }
      .newsletter__Submit:hover:before {
        opacity: 1;
        right: -40px;
        left: -40px; }
    .newsletter__Submit:before {
      content: '';
      display: block;
      z-index: -1;
      position: absolute;
      top: -10px;
      bottom: -10px;
      transform: skew(40deg);
      background: #FFF;
      left: 100%;
      right: 100%;
      transition: 900ms cubic-bezier(0.23, 1, 0.32, 1); }
    .newsletter__Submit:hover {
      background: #000;
      color: #FFF; }

.page-template-page-products .siteFooter {
  margin-top: -100px !important; }

.goldenImg {
  max-width: 80px !important;
  margin-right: 10px; }

.blockMobile__Logo {
  display: none; }

.nav, .nav--Footer {
  width: 100%;
  position: relative;
  font-size: 14px;
  letter-spacing: 2.38px;
  float: left;
  text-align: left; }
  .nav ul, .nav--Footer ul {
    padding: 0;
    margin: 0 auto;
    list-style-type: none;
    text-align: left;
    display: table;
    height: 60px;
    line-height: 60px; }
  .nav li, .nav--Footer li {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    position: relative; }
  .nav a, .nav--Footer a {
    color: #FFF;
    text-transform: uppercase;
    font-size: 1em;
    text-decoration: none;
    line-height: 60px;
    margin: 0;
    display: block;
    position: relative;
    transition: 300ms cubic-bezier(0.86, 0, 0.07, 1);
    padding: 0 10px; }
    .nav a:before, .nav--Footer a:before {
      content: '';
      position: absolute;
      z-index: -1;
      width: 0;
      height: 0;
      bottom: -8px;
      left: 50%;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: #777 transparent transparent;
      transform: translateX(-50%);
      opacity: 0;
      transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
    .nav a:hover, .nav--Footer a:hover {
      color: #FFF;
      transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
      .nav a:hover:before, .nav--Footer a:hover:before {
        opacity: 1;
        transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
  .nav .current-menu-item a:before, .nav--Footer .current-menu-item a:before {
    opacity: 1;
    transition: 300ms cubic-bezier(0.86, 0, 0.07, 1); }
  .nav--Footer {
    width: 70%;
    height: 100px;
    line-height: 100px; }
    .nav--Footer ul {
      width: auto; }
      .nav--Footer ul li {
        border-radius: 0;
        border: 0;
        width: auto;
        height: auto; }
    .nav--Footer a {
      color: #999;
      height: 100px;
      line-height: 100px;
      font-size: 12px;
      font-weight: 300;
      text-transform: initial;
      letter-spacing: normal; }
      .nav--Footer a:hover {
        color: #000; }
      .nav--Footer a:before {
        display: none; }

.labelMenu {
  position: absolute !important;
  z-index: 10000;
  width: 200px; }
  .labelMenu__Top {
    height: 60px;
    line-height: 60px;
    color: #777;
    cursor: pointer; }
    .labelMenu__Top img {
      width: 60px;
      float: left;
      line-height: 60px;
      padding-top: 10px; }
    .labelMenu__Top h3 {
      font-size: 30px;
      text-transform: uppercase;
      font-weight: 400;
      margin: 0;
      float: left;
      line-height: 60px;
      margin-right: 10px; }
    .labelMenu__Top i {
      float: left;
      line-height: 60px;
      transform: rotate(-90deg);
      width: 10px;
      transition: transform 0.1s linear; }
  .labelMenu ul {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    float: right;
    margin: 0;
    padding: 0;
    padding-right: 20px;
    width: 100%;
    background: #f5f5f5; }
    .labelMenu ul li {
      display: block;
      padding: 0 0 5px 0;
      float: right;
      width: 100% !important;
      text-align: right;
      font-weight: 400;
      text-transform: uppercase;
      color: #777;
      border-bottom: 1px solid #f5f5f5; }
    .labelMenu ul li.current {
      color: #c20e1a; }
    .labelMenu ul a {
      color: inherit;
      text-decoration: none; }
      .labelMenu ul a:hover {
        color: #c20e1a; }
  .labelMenu .menuActive {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear; }
  .labelMenu .i-menuActive {
    transform: rotate(0deg);
    transition: transform 0.1s linear; }

.logoTop {
  position: absolute !important;
  z-index: 10000;
  width: 190px;
  padding-left: 50px; }
  .logoTop__Top {
    height: 50px;
    line-height: 50px;
    color: #777;
    cursor: pointer; }
    .logoTop__Top img {
      width: 50px;
      float: left;
      line-height: 50px;
      padding-top: 10px; }

.mainMenu__Top {
  height: 60px;
  line-height: 60px;
  color: #777;
  cursor: pointer;
  text-align: right;
  position: fixed;
  z-index: 100000000000;
  float: right;
  right: 30px;
  transition: visibility 0s, opacity 0.5s linear; }
  .mainMenu__Top a.langLink.active {
    display: none; }
  .mainMenu__Top a.langLink {
    text-transform: uppercase;
    text-decoration: none;
    color: #eaeaea;
    font-size: 14px;
    font-weight: 700;
    float: left;
    line-height: 63px;
    height: 60px;
    position: relative;
    right: 80px;
    margin-right: 10px; }
  .mainMenu__Top a.videoLink {
    text-transform: uppercase;
    text-decoration: none;
    color: #FFF;
    font-weight: 300;
    font-size: 11px;
    margin-left: 20px;
    margin-right: 20px;
    display: inline-block;
    float: left;
    position: relative;
    right: 80px;
    background: #c20e1a;
    padding: 15px 17px;
    height: auto;
    line-height: initial;
    margin-top: 10px; }
    .mainMenu__Top a.videoLink img {
      height: 15px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      margin-top: -3px; }
  .mainMenu__Top h3 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
    line-height: 60px;
    margin-right: 5px;
    display: inline-block; }
  .mainMenu__Top i {
    line-height: 60px;
    width: 20px;
    transition: transform 0.1s linear;
    display: inline-block;
    color: #c20e1a;
    font-size: 22px; }

.mainMenu nav {
  visibility: hidden;
  opacity: 0;
  float: right;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: -1000;
  text-align: center;
  transition: all 0.3s linear, visibility 0s;
  height: 100vh; }

.mainMenu ul {
  margin: 0;
  padding: 0;
  text-align: center; }
  .mainMenu ul li {
    display: block;
    padding: 20px 0;
    font-weight: 400;
    text-transform: uppercase; }
    .mainMenu ul li a {
      text-decoration: none;
      color: #FFF;
      float: left;
      width: 100%;
      padding: 10px 0;
      font-size: 40px;
      position: relative;
      z-index: 1;
      transition: 0.3s linear; }
      .mainMenu ul li a:after {
        left: 40%;
        right: 40%;
        bottom: -2%;
        background-color: transparent;
        height: 1px;
        position: absolute;
        content: '';
        opacity: 0;
        z-index: -1;
        transition: 0.3s linear; }
      .mainMenu ul li a:hover {
        transition: 0.3s linear; }
        .mainMenu ul li a:hover:after {
          bottom: 0;
          opacity: 1;
          transition: 0.3s linear;
          background: #FFF;
          left: 45%;
          right: 45%; }

.mainMenu .mainMenuActive {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s linear, visibility 0s;
  z-index: 10000000000; }

@media screen and (max-width: 720px) {
  .mainMenuActive {
    width: 100%;
    bottom: -1000% !important;
    position: fixed !important;
    padding-top: 100px !important; }
  .mainMenu ul li a {
    font-size: 20px; }
  .mainMenu__Top a.langLink {
    float: right;
    right: 170px; }
  .mainMenuActive .center {
    position: relative;
    top: 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    height: 100%; }
    .mainMenuActive .center .menu-glavni-izbornik-container {
      position: relative;
      top: 0%;
      transform: translateY(0%);
      float: left;
      width: 100%; }
  .homeSlider {
    height: auto;
    display: block;
    margin-top: 70px; }
    .homeSlider__Box {
      display: none; }
    .homeSlider__Slide {
      width: 100%;
      height: 40vh;
      display: block; }
      .homeSlider__Slide:hover {
        width: 100%; }
      .homeSlider__Slide a {
        height: 40vh; }
    .homeSlider__Slide2 {
      width: 100%;
      height: 40vh;
      display: block; }
      .homeSlider__Slide2:hover {
        width: 100%; }
      .homeSlider__Slide2 a {
        height: 40vh; }
    .homeSlider__Text {
      width: 100%;
      height: 40vh;
      display: block;
      text-align: center; }
      .homeSlider__Text h2 {
        font-size: 40px;
        text-align: center;
        float: none;
        margin: 0;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        text-align: center; }
      .homeSlider__Text p {
        display: none; }
      .homeSlider__Text img {
        display: none; }
    .homeSlider__Text2 {
      width: 100%;
      height: 40vh;
      display: block;
      text-align: center; }
      .homeSlider__Text2 h2 {
        font-size: 40px;
        text-align: center;
        float: none;
        margin: 0;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        text-align: center; }
      .homeSlider__Text2 p {
        display: none; }
      .homeSlider__Text2 img {
        display: none; }
  .homeSlider__Slide:nth-of-type(2) .homeSlider__Text h2 {
    margin: 0;
    margin-left: 10%;
    float: left;
    text-align: center;
    margin-right: 10%; }
  .homeSlider__Slide:nth-of-type(2):hover h2 {
    margin-left: 10%; }
  .homeSlider__Slide:hover ~ .homeSlider__Box {
    opacity: 1;
    transition: 500ms;
    z-index: 10; }
  .homeSlider__Slide2:hover ~ .homeSlider__Box {
    opacity: 1;
    transition: 500ms;
    z-index: 10; }
  .homeSlider__Slide:hover h2 {
    width: 80% !important; }
  .news__Box {
    width: 100%;
    margin: 0;
    margin-bottom: 20px; }
    .news__Box h4 {
      padding: 0;
      height: auto; }
    .news__Box p {
      padding: 0;
      height: auto; }
  .siteFooter {
    height: auto; }
    .siteFooter__Left {
      width: 100%;
      margin: 0;
      height: auto;
      text-align: center;
      padding: 20px 0; }
      .siteFooter__Left a {
        display: block;
        margin: 0;
        line-height: initial;
        margin-bottom: 20px; }
      .siteFooter__Left span {
        display: block;
        margin: 0;
        line-height: initial; }
    .siteFooter__Right {
      width: 100%;
      margin: 0;
      height: auto; }
      .siteFooter__Right ul {
        width: 100%;
        text-align: center; }
    .siteFooter .nav--Footer {
      display: none; }
  .siteHeader {
    display: none; }
  .innerSlider {
    height: auto;
    padding-top: 100px;
    background: #c20e1a; }
    .innerSlider__Left {
      width: 90%;
      margin: 0 5%;
      padding: 0;
      display: block;
      height: auto;
      text-align: center; }
    .innerSlider__Right {
      width: 80%;
      margin: 0 10%;
      padding: 0;
      display: block;
      height: auto;
      text-align: left;
      right: auto; }
    .innerSlider__Statistics {
      height: auto; }
      .innerSlider__Statistics--wrap {
        height: auto; }
        .innerSlider__Statistics--wrap:before {
          display: none; }
      .innerSlider__Statistics--left {
        width: 80%;
        top: auto;
        transform: translateY(0);
        margin-left: 10%;
        margin-right: 10%; }
      .innerSlider__Statistics--right {
        width: 100%;
        top: auto;
        transform: translateY(0);
        height: auto; }
      .innerSlider__Statistics .circliful {
        display: block;
        clear: both;
        float: none;
        margin: auto;
        margin-bottom: 60px; }
  .techContent__Wrap {
    width: 100%; }
  .techContent__Name h3 {
    display: none; }
  .techContent__Top {
    float: left;
    width: 100%;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #a71c21; }
  .techContent__boxWrapp {
    float: left;
    width: 100%;
    padding-top: 100px; }
  .techContent__middleWrapp {
    float: left;
    width: 100%;
    padding-top: 150px;
    height: auto;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #2a2a2a; }
  .techContent__Box {
    width: 80%;
    padding: 0;
    margin-left: 10%;
    margin-right: 10%;
    height: auto; }
  .techContent__middle {
    float: left;
    width: 100%; }
    .techContent__middle--image {
      width: 100%;
      margin: 0;
      text-align: center; }
      .techContent__middle--image img {
        max-width: 80px; }
    .techContent__middle--content {
      float: left;
      color: #FFF;
      width: 80%;
      margin-left: 10%;
      margin-right: 10%; }
      .techContent__middle--content h3 {
        color: #c20e1a;
        text-transform: uppercase;
        font-size: 16px; }
  .techContent__Bottom {
    background: #2a2a2a;
    float: left;
    width: 100%; }
  .slideNav li a {
    white-space: nowrap;
    text-indent: -9999px;
    background: transparent; }
  .page-template-page-products {
    overflow: hidden; }
  .page-template-page-products .siteFooter {
    margin-top: 50px !important; }
  .folioSlider {
    float: left;
    width: 100%;
    max-height: initial !important;
    margin-bottom: 0 !important;
    height: auto;
    min-height: auto !important; }
    .folioSlider__Box {
      min-height: auto !important;
      height: 100% !important;
      max-height: initial !important; }
      .folioSlider__Box--inner {
        max-width: initial;
        width: 100%;
        height: auto;
        margin: auto;
        top: 0;
        transform: translateY(0); }
    .folioSlider__Image {
      width: 90%;
      display: block;
      left: auto;
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 100px;
      margin-bottom: 50px;
      float: left; }
      .folioSlider__Image .demoImageMobile {
        text-align: center;
        margin: 0;
        padding: 0;
        transition: 100ms;
        text-align: center;
        display: block;
        height: auto;
        width: 100%;
        float: left; }
        .folioSlider__Image .demoImageMobile li {
          list-style: none;
          float: left;
          width: 100%;
          height: auto; }
          .folioSlider__Image .demoImageMobile li img {
            text-align: center;
            margin: auto;
            opacity: 1;
            transition: 100ms;
            float: none; }
        .folioSlider__Image .demoImageMobile li:first-of-type {
          z-index: 1; }
          .folioSlider__Image .demoImageMobile li:first-of-type:after {
            display: none; }
      .folioSlider__Image .threesixty {
        display: none; }
    .folioSlider__Text {
      width: 80%;
      display: block;
      left: auto;
      margin-left: 10%;
      margin-right: 10%; }
  .labelMenu ul {
    margin-top: 70px; }
  .labelMenu__Top {
    position: fixed;
    margin-top: 5px; }
    .labelMenu__Top h3 {
      font-size: 12px; }
  .labelMenu .menuActive {
    position: fixed; }
  .blockMobile {
    background: #000;
    position: fixed;
    width: 100%;
    height: 70px;
    right: 0;
    padding-right: 30px;
    z-index: 10000; }
    .blockMobile__Logo {
      float: left;
      width: 60px;
      display: block; }
      .blockMobile__Logo img {
        max-height: 50px;
        vertical-align: middle; }
  .mainMenu__Top a.videoLink {
    margin-top: 10px;
    float: right;
    position: relative;
    z-index: 100;
    right: 0px;
    margin-right: -20px; }
    .mainMenu__Top a.videoLink span {
      display: none; }
    .mainMenu__Top a.videoLink img {
      margin-left: 0; }
  .mainMenu__Top.activatedMenu {
    background: transparent;
    z-index: 10000000000000; }
  .page-template-page-video .logoTop {
    display: none; }
  .page-template-page-video .mainMenu__Top h3 {
    color: #777 !important; }
  .page-template-page-video .mainMenu__Top i {
    color: #c20e1a !important; }
  .videoPage {
    margin: auto; }
  .defaultSlider__Heading {
    line-height: 50px;
    font-size: 40px; }
  .classicText {
    padding: 50px 5%; }
  .logoTop {
    display: none; }
  .page-template-page-products .blockMobile__Logo {
    display: none; }
  .mainMenu__Top h3 {
    display: none; }
  .page-id-155 .mainMenu__Top a.videoLink {
    margin-left: 10px;
    margin-right: 10px;
    padding: 15px 10px; }
  .page-id-155 .mainMenu__Top a.langLink {
    right: 140px; }
  .labelMenu {
    width: 0% !important; }
  .page-id-530 .labelMenu {
    width: 0% !important; }
  .page-id-530 .mainMenu__Top a.videoLink {
    padding: 5px 7px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 17px; }
  .page-id-530 .labelMenu__Top h3 {
    font-size: 12px !important;
    width: 30%;
    line-height: 1;
    padding-top: 17px; }
  .page-id-530 .mainMenu__Top a.langLink {
    right: 130px; }
  .page-id-530 .mainMenu__Top {
    padding-right: 10px;
    padding-left: 10px;
    right: 0; }
  .folioSlider__Image img {
    height: auto !important; } }

@media screen and (max-width: 960px) {
  .contactPage__Right {
    position: relative;
    width: 100%;
    background: #444;
    height: 400px; }
    .contactPage__Right:before {
      display: none; }
  .contactPage__Inner {
    float: left;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%; }
  .map {
    float: left;
    width: 100%;
    height: 300px;
    transform: translateX(0%); } }
